<template>
    <div id="interface">

    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#interface{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        min-height: 855px;
        border-radius: 5px;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>